<!--
 Displays the island stats 
 Gets the islandCode from the route
 calls the firebase httpsCallable function "scrapfn-checkIslandStats" with the islandCode in the payload
 and returns this object:
 {
    "image": "https://cdn-0001.qstv.on.epicgames.com/YicqxKebfVqeyRhYQY/image/screen_comp.jpeg",
    "promotion_image": "https://fortnite-island-screenshots-live-cdn.ol.epicgames.com/screenshots%2F5069-7568-6802.png",
    "creator": "MochiMouse207",
    "code": "6178-2350-0998",
    "creatorCode": "mochimouse",
    "description": "Play your way from A to Z in this default Deathrun!\n16 players\nKeep an eye out for bonus items along the way\nHave fun!",
    "type": "Creative:Island",
    "title": "Alphabet Deathrun",
    "matchmaking": {
        "selectedJoinInProgressType": 979,
        "playersPerTeam": -1,
        "playerCount": 16,
        "override_Playlist": "",
        "maximumNumberOfPlayers": 16,
        "mmsType": "keep_full",
        "mmsPrivacy": "Public",
        "numberOfTeams": 16,
        "bAllowJoinInProgress": true,
        "minimumNumberOfPlayers": 16,
        "joinInProgressTeam": 255
    },
    "tags": [
        "deathrun",
        "parkour",
        "just for fun",
        "difficulty: easy"
    ],
    "latestVersion": 2,
    "xp": {
        "dataGathering": true,
        "enabled": false
    },
    "islandType": "CreativePlot:oasis_giant",
    "publishedDate": "2023-04-22T17:45:10.836Z",
    "introduction": "100 Level Default Deathrun\nPlay Your Way From A to Z\nFind Bonus Items Along The Way",
    "status": "live"
} 
Displays all this information in a nice way.
Displays a chart of the History data found in /ScrapFNMapsHistory/All as map
 - Each property is a pair with a timestamp and a value, 1683843900000: 740
-->
<template>
  <div class="m-2">
    <b-alert variant="danger" :show="true" v-if="errorMsg">
      {{ errorMsg }}
    </b-alert>
    <div v-else-if="!island">
      Loading...
    </div>
    <map-card-detail v-else :island="island" />
    Player Count History
    <snapshots-chart :snapshots="snapshots" :maps="maps" countKey="pc" />
  </div>
</template>


<script>
import { getLog } from '@/services/log';
import { checkIslandStats } from '@/services/functions';
const log = getLog('Island.vue');

export default {
  components: {
    MapCardDetail: () => import('./components/MapCardDetail.vue'),
    SnapshotsChart: () => import('./components/SnapshotsChart.vue'),
  },
  data() {
    return {
      island: null,
      errorMsg: null,
      snapshots: [],
      maps: [],
    };
  },
  async created() {
    try {
      this.errorMsg = null;
      const islandCode = this.$route.params.islandCode;
      const { data } = await checkIslandStats({ islandCode });
      let { map, playerCountHistory } = data;
      log.info("island", map, playerCountHistory);
      this.island = map;
      if (!map)
        this.errorMsg = "Island not found";
      this.snapshots = this.convertChart(playerCountHistory, map);
    } catch (error) {
      this.errorMsg = error.message;
      log.error(error);
    }
  },
  methods: {
    // convert playerCountHistory into a chart
    convertChart(playerCountHistory, map) {
      log.log("convertChart", playerCountHistory, map);
      let snapshots = [];
      for (const [time, pc] of Object.entries(playerCountHistory)) {
        let timestamp = { toDate: () => new Date(parseInt(time)) };
        let m = {
          name: map.title,
          islandCode: map.code,
          pc,
        };
        snapshots.push({
          timestamp,
          maps: [m],
        });
      }
      this.maps = [{
          name: map.title,
          islandCode: map.code,
      }];
      return snapshots;
    }
  }
};
</script>
